import style from "./Feedback.module.scss"
import {Heading} from "../../components/Heading/Heading";
import {Button} from "../../components/Button/Button";
import React, {useEffect, useState} from "react";
import {useForm} from "@formspree/react";
import {useFormik} from "formik";
import {SuccessWindow} from "../../components/SuccessWindow/SuccessWindow";
import {useParams} from "react-router-dom";
import {useFetchAllToursQuery} from "../../store/toursSlice";

export function Feedback() {
    const [successWindow, setSuccessWindow] = useState(false)
    const [errorWindow, setErrorWindow] = useState(false)
    const [state, handleSubmit] = useForm("mrbgzjwj");

    const formik = useFormik({
        initialValues: {
            fullName: '',
            post: '',
            company: '',
            comment: '',
        },
        validate: values => {
            const errors = {};

            if (!values.fullName) {
                errors.fullName = 'ФИО обязательно';
            } else if (values.fullName.trim().length < 1) {
                errors.fullName = 'Минимум 2 символа';
            }

            if (!values.post) {
                errors.post = 'Должность обязательна';
            }

            if (!values.company) {
                errors.company = 'Компания обязательна';
            }
            if (!values.comment || values.comment.trim().length < 1) {
                errors.comment = 'Комментарий должен содержать минимум 10 символов';
            }

            return errors;
        },
        onSubmit: async (values, {resetForm}) => {
            const errors = formik.validateForm(values);

            if (Object.keys(errors).length === 0) {
                const data = {
                    "Имя": formik.values.fullName.trim(),
                    "Компания": formik.values.company.trim(),
                    "Должность": formik.values.post.trim(),
                    "Отзыв": formik.values.comment.trim()
                }
                try {
                    await handleSubmit(data)
                    setSuccessWindow(true)
                    resetForm()
                } catch (e) {
                    console.log(e.message)
                    setErrorWindow(true)
                }
            }
        },
    });
    return (<>
            <SuccessWindow visible={successWindow} close={() => setSuccessWindow(false)}>Успешно
                отправлено</SuccessWindow>
            <SuccessWindow error visible={errorWindow} close={() => setErrorWindow(false)}>Ошибка при
                отправке</SuccessWindow>
            <form onSubmit={formik.handleSubmit} className={style.container}>
                <Heading>Оставить отзыв</Heading>
                <div className={style.input_container}>
                    <input
                        placeholder="Фамилия имя"
                        name="fullName"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fullName}
                        className={`${formik.touched.fullName && formik.errors.fullName ? style.error : ""}`}
                    />
                </div>
                <div className={style.input_container}>
                    <input
                        placeholder="Компания"
                        name="company"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company}
                        className={`${formik.touched.company && formik.errors.company ? style.error : ""}`}
                    />
                </div>
                <div className={style.input_container}>
                    <input
                        placeholder="Должность"
                        name="post"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.post}
                        className={`${formik.touched.post && formik.errors.post ? style.error : ""}`}
                    />
                </div>
                <textarea
                    placeholder="Комментарий"
                    name="comment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    className={`${style.feedback} ${formik.touched.comment && formik.errors.comment ? style.error : ""}`}
                />
                <Button type="submit">Оставить отзыв</Button>
                <p>* нажимая кнопку - "Оставить отзыв", вы даете свое согласие на обработку персональных данных</p>
            </form>
        </>
    )
}