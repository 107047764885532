import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import style from './Request.module.scss';
import {Heading} from "../../components/Heading/Heading";
import {Button} from "../../components/Button/Button";
import {useForm} from "@formspree/react";
import {SuccessWindow} from "../../components/SuccessWindow/SuccessWindow";
import {useParams} from "react-router-dom";
import {useFetchAllToursQuery} from "../../store/toursSlice";

export function Request() {
    const [successWindow, setSuccessWindow] = useState(false)
    const [errorWindow, setErrorWindow] = useState(false)
    const [state, handleSubmit] = useForm("xyzygork");
    const {id} = useParams();
    const {data} = useFetchAllToursQuery();
    const [tourName, setTourName] = useState(false)
    useEffect(() => {
        if (data) {
            const tour = data.find(el => el.id === +id);
            if (tour) {
                setTourName(tour.title)
            } else {
                setTourName("")
            }
        }
    }, [id, data])
    const formik = useFormik({
        initialValues: {
            fullName: '',
            phone: '',
            email: '',
            company: '',
            comment: '',
        },
        validate: values => {
            const errors = {};

            if (!values.fullName) {
                errors.fullName = 'ФИО обязательно';
            } else if (values.fullName.trim().length < 2) {
                errors.fullName = 'Минимум 2 символа';
            }

            // if (!values.phone) {
            //     errors.phone = 'Телефон обязателен';
            // } else if (!/^\+?\d{10,15}$/.test(values.phone)) {
            //     errors.phone = 'Некорректный номер телефона';
            // }

            if (!values.email) {
                errors.email = 'Email обязателен';
            } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                errors.email = 'Некорректный email';
            }

            if (!values.company) {
                errors.company = 'Компания обязательна';
            }
            // if (!values.comment || values.comment.trim().length < 10) {
            //     errors.comment = 'Комментарий должен содержать минимум 10 символов';
            // }

            return errors;
        },
        onSubmit: async (values, {resetForm}) => {
            const errors = formik.validateForm(values); // Валидация вручную

            if (Object.keys(errors).length <= 2) {
                const data = {
                    "Название тура": tourName,
                    "Имя": formik.values.fullName.trim(),
                    "Почта": formik.values.email.trim(),
                    "Телефон": formik.values.phone.trim(),
                    "Компания": formik.values.company.trim(),
                    "Отзыв": formik.values.comment.trim()
                }
                try {
                    if (!tourName.length) {
                        throw new Error("Не определено название тура")
                    }
                    await handleSubmit(data)
                    setSuccessWindow(true)
                    resetForm()
                } catch (e) {
                    console.log(e.message)
                    setErrorWindow(true)
                }
            }
        },
    });

    return (<>
            <SuccessWindow visible={successWindow} close={() => setSuccessWindow(false)}>Успешно
                отправлено</SuccessWindow>
            <SuccessWindow error visible={errorWindow} close={() => setErrorWindow(false)}>Ошибка при
                отправке</SuccessWindow>
            <div className={style.container}>
                <Heading>Оставить заявку</Heading>
                <form className={style.form_container} onSubmit={formik.handleSubmit}>
                    <div className={style.input_container}>
                        <input
                            placeholder="Фамилия имя"
                            name="fullName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fullName}
                            className={`${formik.touched.fullName && formik.errors.fullName ? style.error : ""}`}
                        />
                    </div>
                    <div className={style.input_container}>
                        <input
                            placeholder="Телефон"
                            name="phone"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            className={`${formik.touched.phone && formik.errors.phone ? style.error : ""}`}
                        />
                    </div>
                    <div className={style.input_container}>
                        <input
                            placeholder="Почта"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={`${formik.touched.email && formik.errors.email ? style.error : ""}`}
                        />
                    </div>
                    <div className={style.input_container}>
                        <input
                            placeholder="Компания"
                            name="company"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company}
                            className={`${formik.touched.company && formik.errors.company ? style.error : ""}`}
                        />
                    </div>
                    <div className={style.input_container}>
                <textarea
                    placeholder="Комментарий"
                    name="comment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    className={`${style.request} ${formik.touched.comment && formik.errors.comment ? style.error : ""}`}
                />
                    </div>
                    <Button type="submit">Оставить заявку</Button>
                    <p>* нажимая кнопку - "Оставить заявку ", вы даете свое согласие на обработку персональных данных</p>
                </form>
            </div>
        </>
    );
}
