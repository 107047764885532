import style from "./SuccessWindow.module.scss"
import {useEffect} from "react";

export function SuccessWindow({visible, close, error, children}) {
    useEffect(()=>{
        const timer = setTimeout(close, 2000)
        return ()=>clearTimeout(timer)
    }, [visible, close])
    return(
        <div className={`${style.container} ${visible ? style.visible : ""} ${error ? style.error : ""}`}>
            {children}
        </div>
    )
}