import style from "./Archive.module.scss"
import {Card} from "../../components/Card/Card";
import {useFetchAllToursQuery} from "../../store/toursSlice";
import {useEffect, useState} from "react";

export function Archive() {
    const {data, isLoading, error} = useFetchAllToursQuery()
    const [tours, setTours] = useState()
    console.log(data)
    useEffect(()=>{
        if(data){
            const archiveTours = data.filter(el=>el.is_archive===true)
            setTours(archiveTours)
        }
    },[data])
    return(
        <div className={style.content}>
            {isLoading && <div><img className={style.loading} src={require("../../assets/loadind.gif")} alt="loading"/></div>}
            {error && <div className={style.error}>Ошибка при загрузке туров</div>}
            {(!isLoading && !error && tours && tours.length === 0) && <div>Нет туров</div>}
            {tours && tours.map(el => <Card {...el} link={`/tour/${el.id}`} />)}
        </div>
    )
}