import style from "./FeedbackBookCard.module.scss"
import {CardContainer} from "../CardContainer/CardContainer";

export function FeedbackBookCard(props) {
    return(
        <CardContainer>
            <div className={style.container}>
                <p className={style.text}>{props.post}</p>
                <p className={style.name}>{props.name}</p>
                <p className={`${style.text} ${style.text_feedback}`} dangerouslySetInnerHTML={{ __html: props.text }}/>
            </div>
        </CardContainer>
    )
}