import photo1 from "../assets/Дарья Семина.webp"
import photo2 from "../assets/Алена Несифорова.webp"
export const ABOUT_US = [
    {
        name: "Дарья Сёмина",
        text: "Проект Secret Sauce стал ответом изменившейся реальности. Российский бизнес был всегда интегрирован в мировой контекст. Если в нулевых мы догоняли европейские бренды, то во второй половине десятых уже сами задавали тренды. Менеджеры, креаторы, топы российских компаний ездили по всему миру: они учились и учили сами. Но в конце 2019-го мир изменился: пандемия и политическая ситуация этот процесс как минимум затормозили.",
        photo: photo1
    },
    {
        name: "Алена Несифорова",
        text: "Мы — выходцы из ВкусВилла, мы знаем как важен этот бесконечный обмен идеями и опытом. Я со ВкусВиллом побывала в десятках стран и исполняла разные роли: сначала просто наблюдала, затем формулировала запросы от имени подразделений и всей компании, ну и конечно, принимала активное участие в самих турах. А в этом году я начала  сама  организовывать поездки. Мы знаем тот самый секретный ингредиент успешного бизнес-тура.",
        photo: photo2
    },
    {
        name: "Дарья Сёмина",
        text: "Позволю себе спойлер: весь секрет в балансе. Идеальный финал любого бизнес-тура — вдохновленная команда, которая нашла свежие идеи и готова их реализовывать. Кажется, чем больше будет встреч, лекций и экскурсий, тем лучше. Это заблуждение. Кроме правильных собеседников нужны удобные автобусы, комфортные отели, разнообразный досуг, время для дискуссий и сна. Если обо всем этом не подумать, вы получите миску с выжатыми лимонами вместо кувшина с бодрящим лимонадом.",
        photo: photo1
    },
    {
        name: "Алена Несифорова",
        text: "Наши клиенты — крупный и средний бизнес из России. На старте мы вместе с заказчиком формулируем ключевой запрос. Например, вы хотите масштабировать свое производство косметики. Это значит, наш путь будет лежать к лидерам сегмента — в Азию. Мы на своей стороне анализируем бьюти-рынок: выбираем подходящие кейсы с точки зрения производства, дистрибуции и маркетинга и договариваемся с компаниями о встречах и экскурсиях. Как только вы согласовываете основную программу, она начинает обрастать дополнительными атрибутами: праздничными обедами, развлекательной программой, бонусными лекциями и экскурсиями. Кстати, наша география не ограничивается Азией: мы организуем туры в Индию, Арабские Эмираты и Европу.",
        photo: photo2
    },
    {
        name: "Дарья Сёмина",
        text: "Мы смело можем назвать себя экспертами в ритейле, eCom, инновациях, мобильных и IT-технологиях. Но мы готовы браться за любые нестандартные задачи. Мы глубоко погружаемся в каждый сегмент рынка: прорабатываем вопросы и общаемся со всеми экспертами, спикерами и переводчиками, чтобы быть уверенными в качестве информации. А если мы не сможем помочь вам сами, мы гарантированно подберем вам проводника с высокой экспертизой. Ведь главное для нас — качественная помощь бизнесу.",
        photo: photo1
    },
]