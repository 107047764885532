import style from "./FeedbackBook.module.scss"
import {Heading} from "../../components/Heading/Heading";
import {FeedbackBookCard} from "../../components/FeedbackBookCard/FeedbackBookCard";
import {FEEDBACK_BOOK} from "../../data/feedback_book";
import {Button} from "../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export function FeedbackBook() {
    const navigate = useNavigate()
    useEffect(()=>{navigate("/")},[])
    return(
        <div className={style.container}>
            <Heading>Ваши впечатления</Heading>
            {FEEDBACK_BOOK.map((el, index)=><FeedbackBookCard key={index} {...el}/>)}
            <Button onClick={()=>navigate(`/feedback`)}>Оставить отзыв</Button>
        </div>
    )
}