import { forwardRef, useState, useEffect, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { Document, Page, pdfjs } from 'react-pdf';
import style from './TourBook.module.scss';
import './styles.css';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import { useFetchAllToursQuery } from '../../store/toursSlice';
import { setPort } from "../../hooks/useSetPort";
import pdf from "../../assets/document.pdf";
import {Button} from "../../components/Button/Button";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function blobToURL(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
    });
}

const Pages = forwardRef((props, ref) => (
    <div className="demoPage" ref={ref}>
        <p>{props.children}</p>
    </div>
));

Pages.displayName = 'Pages';

function Flipbook() {
    const { id } = useParams();
    const [pdfUrl, setPdfUrl] = useState('');
    const { data, isLoading } = useFetchAllToursQuery();
    const [numPages, setNumPages] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pagesToShow, setPagesToShow] = useState([1, 2]);
    const [loading, setLoading] = useState(true);
    const [isWideScreen, setIsWideScreen] = useState(false);
    const navigate = useNavigate()
    const { pathname } = useLocation();


    const flipbookRef = useRef(null); // создаем реф для FlipBook

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setIsLoaded(true);
    }

    useEffect(() => {
        if (window.innerWidth > 1500) {
            setIsWideScreen(true);
        } else {
            setIsWideScreen(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (numPages > 0 && pagesToShow.length < 2) {
            setPagesToShow([1, 2]);
        }
    }, [numPages, pagesToShow]);

    useEffect(() => {
        const fetchPdf = async () => {
            if (!isLoading && data && id) {
                const tour = data.find(el => el.id === +id);
                if (tour && tour.content) {
                    const newURL = setPort(tour.content);
                    try {
                        const response = await fetch(newURL, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/pdf'
                            }
                        });
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const blob = await response.blob();
                        const url = await blobToURL(blob);
                        setPdfUrl(url);
                    } catch (error) {
                        console.error('Error fetching PDF:', error);
                    }
                }
            }
        };

        fetchPdf();
    }, [data, id, isLoading]);

    return (
        <div className={style.window}>
            {(loading || !isLoaded || !pdfUrl) && (
                <div className={style.loadingOverlay}>
                    <img src={require("../../assets/loadind.gif")} alt="Loading..." className={style.loadingGif} />
                </div>
            )}
            {pathname!=="/" && <Button className={style.back} onClick={() => navigate(-1)}>Назад</Button>}
            {pdfUrl && (
                <>
                    <div className={style.container}>
                        <HTMLFlipBook
                            width={isWideScreen ? 700 : 400}
                            height={isWideScreen ? 997 : 570}
                            ref={flipbookRef} // передаем реф
                        >
                            {pagesToShow.map(pNum => (
                                <Pages key={pNum} number={pNum}>
                                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page
                                            pageNumber={pNum}
                                            width={isWideScreen ? 700 : 400}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                        />
                                    </Document>
                                </Pages>
                            ))}
                            {[...Array(numPages).keys()].slice(2).map(pNum => (
                                <Pages key={pNum + 1} number={pNum + 1}>
                                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                                        <Page
                                            pageNumber={pNum + 1}
                                            width={isWideScreen ? 700 : 400}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                        />
                                    </Document>
                                </Pages>
                            ))}
                        </HTMLFlipBook>
                    </div>

                    <div>
                        <Button className={style.swipe} onClick={() => flipbookRef.current.pageFlip().flipPrev()}>
                            &lt;
                        </Button>
                        <Button className={style.swipe} onClick={() => flipbookRef.current.pageFlip().flipNext()}>
                            &gt;
                        </Button>
                    </div>

                    <div className={style.buttons}>
                        <a className={style.download} href={pdfUrl} download="document.pdf">
                            Скачать файл
                        </a>
                        <Link to={`/request/${id}`} className={style.download}>Оставить заявку</Link>
                    </div>
                </>
            )}
        </div>
    );
}

export default Flipbook;
