export function setPort(currentUrl) {
    if(currentUrl.includes("thesecretsauce.ru")){
        return currentUrl
    }else {
        try {
            const newURL = new URL(currentUrl);
            newURL.port = 8009;
            return newURL.href;
        } catch (error) {
            return '';
        }
    }
}