import './App.css';
import {MainPage} from "./pages/MainPage/MainPage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Flipbook from "./pages/TourBook/TourBook";
import {MainLayout} from "./layouts/MainLayout/MainLayout";
import {AboutUs} from "./pages/AboutUs/AboutUs";
import {FeedbackBook} from "./pages/FeedbackBook/FeedbackBook";
import {Feedback} from "./pages/Feedback/Feedback";
import {Archive} from "./pages/Archive/Archive";
import {Request} from "./pages/Request/Request";

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {
                path: '',
                element: <MainPage/>
            },
            {
                path: '/about_us',
                element: <AboutUs/>
            },
            {
                path: '/feedback_book',
                element: <FeedbackBook/>
            },
            {
                path: '/archive',
                element: <Archive/>
            },
            {
                path: '/feedback',
                element: <Feedback/>
            },
            {
                path: '/request/:id',
                element: <Request/>
            },
        ]
    },
    {
        path: '/tour/:id',
        element: <Flipbook/>
    }
])
function App() {
  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
