import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PREFIX } from "./API";

export const toursAPI = createApi({
    reducerPath: 'toursAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: PREFIX,
        prepareHeaders: (headers) => {
            const token = process.env.REACT_APP_TOKEN;
            if (token) {
                headers.set('X-API-KEY', token);
            }
            return headers;
        },
    }),
    tagTypes: ['Tours'],
    endpoints: (build) => ({
        fetchAllTours: build.query({
            query: () => ({
                url: '/api/tours/',
            }),
            providesTags: result => ['Tours'],
            extraOptions: { maxRetries: 3 },
        })
    })
});

// Ensure you export the generated hooks
export const { useFetchAllToursQuery } = toursAPI;
