import style from "./MainLayout.module.scss"
import logo from "../../assets/logo.png";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Button} from "../../components/Button/Button";

export function MainLayout() {
    const { pathname } = useLocation();
    const navigate = useNavigate()

    useEffect(()=>{
        window.scrollTo(0,0)
    },[pathname])
    return(
        <div className={style.container}>
            {pathname!=="/" && <Button className={style.back} onClick={() => navigate(-1)}>Назад</Button>}
            <div className={style.header}>
                <div className={style.logo}><img src={logo} alt="logo"/></div>
                <h1 className={style.telegram}>Secret Sauce</h1>
                <p>Бизнес путешествия за большими идеями</p>
            </div>
            <Outlet/>
        </div>
    )
}