import style from "./AboutUs.module.scss"
import {AboutUsCard} from "../../components/AboutUsCard/AboutUsCard";
import {ABOUT_US} from "../../data/about_us";
import {Heading} from "../../components/Heading/Heading";

export function AboutUs() {
    return(
        <div className={style.container}>
            <Heading >О нас</Heading>
            {ABOUT_US.map((el, index) =><AboutUsCard key={index} {...el}/>)}
        </div>
    )
}