import style from "./Card.module.scss"
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {setPort} from "../../hooks/useSetPort";

export function Card(props) {
    const [url, setUrl] = useState('');
    useEffect(() => {
        if (props.cover) {
            const newUrl = setPort(props.cover);
            setUrl(newUrl);
        }
    }, [props.cover]);

    return (
        <Link to={props.link} className={style.container}>
            {url ? <img className={style.cover} src={url} alt="cover" /> : <img className={style.cover} src={props.cover} alt="cover" />}
            <p>{props.title}</p>
        </Link>
    );
}
