import style from "./AboutUsCard.module.scss"
import {CardContainer} from "../CardContainer/CardContainer";

export function AboutUsCard(props) {
    return (
        <CardContainer>
            <div className={style.container}>
                <div>
                    <div className={style.top}>
                        {props.photo ? <img className={style.photo} src={props.photo} alt="person"/> :
                            <div className={style.photo}></div>}
                        <p className={style.name}>{props.name}</p>
                    </div>
                    <p className={style.text}>{props.text}</p>
                </div>
                <img className={style.photo_desktop} src={props.photo} alt="photo"/>
            </div>
        </CardContainer>
    )
}