import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {toursAPI} from "./toursSlice";

const rootReducer = combineReducers({
    [toursAPI.reducerPath]: toursAPI.reducer
})
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(toursAPI.middleware)
})
