export const FEEDBACK_BOOK = [
    {
        post: "Управляющая по онлайн-маркетингу ВкусВилл",
        name: "Анна Платова",
        text: "<p>Бизнес и культура компании ВкусВилл во многом строятся на тех впечатлениях и знаниях, которые команда привозит из поездок по всему миру. Бизнес-тур в Китай невероятно расширил мой профессиональный кругозор. Китайский ритейл и сервисы доставки продуктов впечатляют глубиной оптимизации, продуманностью каждого процесса. Поразительно: они достигли этих результатов всего за несколько лет. На свои процессы я теперь смотрю через призму полученных в бизнес-туре знаний и впечатлений.</p>" +
            "<p>Мои наилучшие рекомендации и благодарность организаторам за невероятно мощную бизнес-программу и встречи с лидерами ритейла и доставки продуктов Китая. Понимаю, как это было непросто, ведь китайские компании не всегда открыто делятся экспертизой. В нашем туре я оценила высокий уровень открытости и доступности ценной информации, возможность живого общения с ключевыми сотрудниками лидеров рынка, обсуждения конкретных кейсов. Такие бизнес-туры действительно двигают бизнес вперёд и обогащают команды.</p>"
    },
    {
        post: "Руководитель административно-хозяйственного отдела доставки ВкусВилл",
        name: "Светлана Ветрова",
        text: "<p>Кажется, самое банальное, о чем можно говорить после поездки в другую страну — это расширение кругозора. Но это истинная правда. Китай показал, насколько иначе может строиться повседневная жизнь: от устройства транспортной системы до адаптации жилья под новые потребности людей — например, нежелание готовить и желание иметь больше свободного пространства.</p>" +
            "<p>Поездка помогла мне найти массу идей для тестирования в России и показала, что многие решения нашей команды совершенно справедливы, а порой и превосходят по эффективности идеи коллег из Азии. Такая “сверка часов” придает уверенности. Кстати, возможность общаться напрямую с иностранными коллегами в поездке и после в соцсетях — бесценна. Уже сегодня мы готовимся заключить первое соглашение о сотрудничестве с коллегами из Китая. С коллегами из России общение тоже изменилось. Обсуждая чужой опыт, мы стали лучше понимать друг друга и в повседневных вопросах.</p>"
    }
]